.profile-image {
    border-radius: 10%;
    width: auto;
    max-width: 250px;
    margin: 20px;
    @media screen and (min-width: 600px) {
        float: right;
    }
}

.site-header {
    background-color: $lm-site-highlight-color;
    border: none;
    line-height: 80px;
    color: $lm-site-highlight-color2;
}

.post-link {
    font-size: 1.2rem;
}

.site-footer {
    background-color: $lm-site-highlight-color;
    border: none;
}
.footer-col-wrapper {
    color: $lm-site-highlight-color2;
    a {
        color: $lm-site-highlight-color2;
    }
}

.page-content {
    padding: 50px 0;
}

.page-link {
    color: $lm-site-highlight-color2;
}

.post-meta {
    color: $lm-text-color;
}

h1 {
 font-size: 1.8rem !important;
 color: $lm-brand-color-dark;
}

h2 {
 font-size: 1.5rem !important;
 color: $lm-brand-color-dark;
}

h3 {
 font-size: 1.1rem !important;
 color: $lm-brand-color-dark;
}

article p, article ol, article ul, .home p {
    font-family: $post-font-family;
}

.site-title {
  color: $lm-site-highlight-color2 !important;
  font-size: 1.8rem;
  font-family:$base-font-family;

  &:hover {
    text-decoration: none;
  }
}

@media screen and (min-width: 600px) {
    .site-nav .page-link {
        color: $lm-site-highlight-color2;
    }
}

@media screen and (max-width: 599px) {
    .site-nav .page-link {
        color: $lm-site-highlight-color;
        margin-left: 50px;
        line-height: 2;
    }
}

.contact-list li {
   list-style: disclosure-closed inside;
}

.imprint a {
    color: $lm-site-highlight-color2;
    font-size: 0.9375rem;
    font-weight: 200;
}

.references {
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: auto;
  column-gap: 30px;
  row-gap: 30px;
  display: grid;
  margin: 20px 0;
}

.reference {
    text-align: center;
    align-self: stretch;
    border-radius: 10px;
    font-size: 0.9rem;
    div {
        margin-bottom: 15px;
        b {
            font-size: 1.2rem;
             color: $lm-brand-color-dark;
        }
        img {
            border-radius: 50%;
            width: 150px;
            display: block;
            margin: 0 auto 15px;
        }
    }
    span {
        font-size: 30px;
    }
    p {
        font-style: italic;
    }
}
